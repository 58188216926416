<template>
    <div class="container">
        <div class="row justify-content-center g-0">
            <div class="col-xs-12 col-md-4 col-lg-3">
                <Profile />
            </div>
            <div class="col-xs-12 col-md-8 col-lg-9">
                <div class="main-wrapper">
                    <Section>
                        <Article heading="Who am I?" subheading="">
                            <template v-slot:text>
                                Hello there! My name is Alexander and I’m an aspiring software engineer. I love learning and applying new technologies to make interesting and meaningful projects. Whether it be creating my own knockoff versions of popular products or simulating cats procreating over time, every endeavor has rigorously trained my ability to grow and adapt to any situation.
                            </template>
                        </Article>
                    </Section>
                    <Projects />
                    <Skillset />
                    <Interests />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Section from '@/components/Section';
import Article from '@/components/Article';

import Profile from '@/components/Profile';
import Skillset from '@/components/Skillset';
import Projects from '@/components/Projects';
import Interests from '@/components/Interests';

export default {
    name: 'Home',
    components: {
        Article,
        Section,
        Profile,
        Skillset,
        Projects,
        Interests
    }
}
</script>

<style lang="scss">
.main-wrapper {

    display: flex;
    flex-flow: column;
    justify-content: space-evenly;

    margin-top: 24px;
    margin-bottom: 24px;
    
    
}

.main {

    .main-head {
        font-size: 32px;
        font-weight: 700;
    }
    .main-text {
        font-size: 16px;
        font-weight: 300;

        white-space: wrap;
        overflow-wrap: break-word;
    }
}

</style>