<template>
    <div class="ico">
        <img :src="uri" :class="classObject"/>
    </div>
</template>

<script>

export default {
    name: "Ico",
    props: [
        "dataObj",
        "size"
    ],
    computed: {
        classObject() {
            return {
                "ico-sm": this.size === "sm",
                "ico-md": this.size === "md",
            };
        },
        uri() {
            return "/assets/icons/" + "icons8-" + this.dataObj + "-50.png";
        }
    }
}
</script>
<style lang="scss">
    .ico {
        img {
            
            &.ico-sm {
                width: 25px;
                height: 25px;
            }
            &.ico-md {
                width: 50px;
                height: 50px;
            }
        }
    }
</style>