<template>
    <div class="round-image">
        <img :src="src" />
    </div>
</template>

<script>

export default {
    name: "RoundImage",
    props: [
        "src"
    ]
}
</script>

<style lang="scss">
    .round-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;

            border-radius: 50%;
            border-style: solid;
            border-width: 5px;
            border-color: var(--muted);
            
            padding: 12px;
            margin: 12px;

            object-fit: cover;
        }
    }
</style>