<template>
    <MiniSection :header="this.header">
        <Icon v-for="obj in this.dataArray" 
            :key="obj.file" :dataObj="obj" />
    </MiniSection>
</template>

<script>
import Icon from '@/components/Icon';
import MiniSection from '@/components/MiniSection';

export default {
    name: "Icons",
    components: {
        MiniSection,
        Icon
    },
    props: [
        "dataArray",
        "header"
    ]
}
</script>