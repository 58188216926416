export default {
    items: [
        { name: "Java", file: "java" },
        { name: "Golang", file: "golang" },
        { name: "Kubernetes", file: "kubernetes" },
        { name: "Javascript", file: "javascript" },
        { name: "Python", file: "python" },
        { name: "HTML", file: "html-5" },
        { name: "CSS", file: "css3" },
        { name: "Sass", file: "sass" },
        { name: "React", file: "react-native" },
        { name: "Bash", file: "console" },
        { name: "C", file: "c" },
        { name: "C#", file: "c-sharp-logo" },
        { name: "Angular", file: "angularjs" },
        { name: "Typescript", file: "typescript" },
        { name: "Redux", file: "redux" },
        { name: "Vue", file: "vue-js" },
        { name: "Webpack", file: "webpack" },
        { name: "Node JS", file: "nodejs" },
        { name: "Wordpress", file: "wordpress" },
        { name: "Bootstrap", file: "bootstrap" },
        { name: "Git", file: "git" },
        { name: "Docker", file: "docker" },
        { name: "Npm, Yarn, Maven", file: "npm" },
        { name: "Azure", file: "azure" },
        { name: "Linux", file: "linux" },
        { name: "Heroku", file: "heroku" },
        { name: "Virtualbox", file: "virtualbox" },
        { name: "Cloudflare", file: "cloudflare" },
        { name: "DNS", file: "website" },
        { name: "NGINX", file: "nginx" },
        { name: "Photoshop", file: "adobe-photoshop" },
        { name: "Premiere", file: "adobe-premiere-pro" },
        { name: "Open Broadcaster Software (OBS)", file: "obs-studio" }
    ]
}