<template>
    <div class="skillset">
        <Section>
            <Article heading="Skillset" subheading="" >
                <template v-slot:text>
                    Behold, the alphabet soup of frameworks and technologies I have laid my eyes on...
                </template>
                <Icons :dataArray="all" header="" />
            </Article>
        </Section>
    </div>
</template>

<script>
// @ is an alias to /src
import Section from '@/components/Section';
import Article from '@/components/Article';
import Icons from '@/components/Icons';

//Data

import All from '@/data/all';

export default {
    name: 'Skillset',
    components: {
        Section,
        Article,
        Icons
    },
    computed: {
        all() {
            return All.items;
        }
    }
}
</script>