<template>
    <div class="footer">
        <div class="footer-text-wrapper"> 
            <p class="footer-text">Copyright {{currentYear}} QxBytes. Icons by <a href="https://www.icons8.com">Icons8</a>.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    computed: {
        currentYear() {
            return new Date().getYear() + 1900
        }
    }
}
</script>

<style lang="scss">
    .footer {
        width: 100%;
        height: 120px;

        .footer-text-wrapper {
            position: absolute;

            bottom: 12px;
            right: 12px;

            left: 50%;

            .footer-text {
                position: relative;
                left: -50%;

                text-align: center;

                font-size: 14px;
                font-weight: 300;
            }
        }
    }
</style>