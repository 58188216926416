<template>
    <div class="icon">
        <Ico :dataObj="dataObj.file" :id="this.dataObj.file" size="md"/>
    </div>
</template>

<script>
import Ico from "@/components/Ico";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default {
    name: "Icon",
    components : {
        Ico
    },
    props: [
        "dataObj"
    ],
    mounted: function() {
        tippy('#' + this.dataObj.file, {
            content: this.dataObj.name
        });
    }
}
</script>
<style lang="scss">

</style>