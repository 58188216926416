<template>
    <div class="link-column">
        <p class="link-column-header"> {{ this.header }} </p>
        <div class="link-row" v-for="obj in this.dataArray" :key="'link ' + obj.name">
            <Ico :dataObj="obj.file" size="sm" />
            <Button class="link-row-button" :href="obj.link" target="_blank"> {{ obj.name }} </Button>
        </div>
    </div>
</template>
<script>
import Ico from '@/components/Ico';
import Button from '@/components/Button';

export default {
    name: "LinkColumn",
    components: {
        Ico,
        Button
    },
    props: [
        "header",
        "dataArray"
    ]
}
</script>
<style lang="scss">
    .link-column-header {
        font-size: 18px;
        font-weight: 500;
    }
    .link-column {
        display: flex;
        flex-flow: column nowrap;
    }
    .link-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        
        .link-row-button {
            background: transparent;
        }
    }
</style>

