<template>
    <div class="full-image-container">
        <div class="event-image" :class="classVariant" @click="toggleFullScreen">
            <img :src="currentImage()" />
        </div>
        <!-- Trigger opening image -->

        <div class="full-image-wrapper" :class="classObject">
            <div class="full-image" @click.self="toggleFullScreen">
                <div class="image">
                    <img :src="currentImage()" />
                    
                </div>
            </div>
            <Button class="image-close" @click="toggleFullScreen">
                <span class="material-icons open-button">fullscreen_exit</span>
            </Button>
            <div v-if="imageArr.length > 1" class="vertical-center-helper">
                <Button class="image-back" @click="adjust(-1)">
                    <span class="material-icons open-button">arrow_back_ios_new</span>
                </Button>
                <Button class="image-forward" @click="adjust(1)">
                    <span class="material-icons open-button">arrow_forward_ios</span>
                </Button>
            </div>
            
        </div>
    </div>
</template>
<script>
import Button from '@/components/Button';

export default {
    name: "FullImage",
    components: {
        Button
    },
    props: [
        "title",
        "imageArr",
        "variant"
    ],
    data() {
        return {
            full: false,
            item: 0
        };
    },
    computed: {
        classVariant() {
            return {
                "image-banner": this.variant === "banner"
            };
        },
        classObject() {
            return {
                "expanded": this.full
            };
        },
    },
    methods: {
        toggleFullScreen() {
            const body = document.getElementsByTagName("body")[0];

            if(this.full) {
                body.classList.remove("disabled");
                this.full = false;

                return;
            }

            body.classList.add("disabled");
            this.full = true;
        },
        currentImage() {
            return this.imageArr[this.item];
        },
        adjust(amount) {
            this.item = (amount + this.item + this.imageArr.length) % this.imageArr.length;
        }
    }
}
</script>

<style lang="scss">
    .event-image {
        position: relative;

        width: 100%;
        max-width: 100%;
        overflow: hidden;
        
        border-radius: 12px;
        // 1:1 width to height ratio
        padding-top: calc(100% / 16 * 9);
        
        

        &.image-banner {
            padding-top: 200px;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;

            left: 0px;
            top: 0px;

            transition: transform .5s ease, filter .5s ease;

            object-fit: cover;

            filter: brightness(100%);

            &:hover {
                transform: scale(1.10, 1.10);
                filter: brightness(80%);
            }

        }
        &::after {
            /* The SCSS seems to add on here, but in others it doesn't */

            position: absolute;
            pointer-events: none;

            content: "MORE";

            background-color: rgba(0, 0, 0, .5);
            color: var(--front);

            border-radius: 6px;

            padding: 6px;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &:hover {

            &::after {
                opacity: 1;
                transition: opacity .15s ease-in;
            }
        }
        &:not(:hover) {
            &::after {
                opacity: 0;
                transition: opacity .15s ease-in;
            }
        }
        /* Indicate you can click it */
        &:not(.active):hover {
            cursor: pointer;
        }
        
        
    }
    .full-image-wrapper {
        &:not(.expanded) {
            display: none;
        }
        &.expanded {
            position: fixed;
            top: 0;
            left: 0;

            height: 100vh;
            width: 100vw;

            z-index: 1000;

            background-color: rgba(0, 0, 0, .5);

            .full-image {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 100vh;
                width: 100vw;
                
                .image {
                    position: relative;

                    background-color: var(--front);
                    border: 12px solid var(--front);

                    border-radius: 6px;
                    img {
                        max-height: 90vh;
                        max-width: calc(90vw - 100px); /* for the arrows */
                        border-radius: 6px;
                    }

                    
                    
                }
            }

            .image-back {
                position: fixed;
                
                top: 50%;
                left: 5%;
                transform: translateX(0) translateY(-50%);
            }
            .image-forward {
                position: fixed;
                
                top: 50%;
                right: 5%;
                transform: translateX(0) translateY(-50%);
            }
            .image-close {
                position: fixed;

                top: 12px;
                right: 12px;
            }
        }
    }
    
</style>