<template>
    <component :is="component" :href="href" :to="to" :target="target" class="button" :class="classObject" @click="click">
        <slot />
    </component>
</template>
<script>
export default {
    name: "Button",

    props: [
        "to",
        "target",
        "href"
    ],

    computed: {
        classObject( ) {
            return {
            };
        },

        component( ) {
            if(this.href) return "a";
            if(this.to) return "router-link";
            return "div";
        }
    },

    methods: {
        click( )  {
            this.$emit("click");
        }
    }
}
</script>

<style lang="scss">
    .button {
        display: inline-flex;
        
        justify-content: center;
        align-items: center;

        background-color: var(--panel);

        font-size: 18px;
        font-weight: 500;

        padding: 6px;

        border-radius: 24px;

        transition: border-radius .1s ease-in;
        transition: transform .1s ease-in;

        &:hover {
            transform: scale(1.1);
            border-radius: 12px;
        }

        &:not(.active):hover {
            cursor: pointer;
        }
    }
</style>