<template>
    <div class="interests">
        <Section>
            <Article heading="Etc." subheading="">
                <template v-slot:text>
                    I swear I have a life outside of coding
                </template>
                
                <MiniSection v-for="obj in interestsList" :header="obj.title" :key="obj.title + ' interest'">
                    <template v-slot:text>
                        <FullImage class="interest-image" variant="banner" :title="obj.title" :imageArr="getFiles(obj)"/>
                    </template>
                </MiniSection>

            </Article>
        </Section>
    </div>
</template>

<script>
// @ is an alias to /src
import Section from '@/components/Section';
import Article from '@/components/Article';
import MiniSection from '@/components/MiniSection';
import FullImage from '@/components/FullImage';

//Data
import MyInterests from '@/data/interests';

export default {
    name: 'Interests',
    components: {
        Section,
        Article,
        MiniSection,
        FullImage
    },
    computed: {
        interestsList() {
            return MyInterests.items;
        }
    },
    methods: {
        getFile(image) {
            return '/assets/interests/' + image + '.png';
        },
        getFiles(obj) {
            return obj.images.map( (image) => this.getFile(image) );
        }
    }
}
</script>

<style lang="scss">

</style>