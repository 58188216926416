<template>
    <div class="projects">
        <Section>
            <Article heading="Projects" subheading="">
                <template v-slot:text>
                    Check out some of my 
                    <u><router-link to="/projects" class="item-link" exact>projects</router-link></u>!
                </template>
            </Article>
        </Section>
    </div>
</template>

<script>
// @ is an alias to /src
import Section from '@/components/Section';
import Article from '@/components/Article';

//Data

export default {
    name: 'Projects',
    components: {
        Section,
        Article
    },
    computed: {
        
    }
}
</script>