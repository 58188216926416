<template>
    <div class="navigation-bar d-flex align-items-center">
        <div class="container d-flex align-items-center">
            <router-link to="/" class="item-link" exact>
                <img class="navigation-bar-logo" src="/assets/logo.png">
            </router-link>
            <ul class="navigation-bar-menu ml-auto d-none d-sm-flex">
                <li class="menu-item">
                    <router-link to="/" class="item-link" exact>HOME</router-link>
                </li>

                <li class="menu-item">
                    <router-link to="/projects" class="item-link">PROJECTS</router-link>
                </li>
            </ul>
            <ul class="navigation-bar-menu ml-auto d-flex d-sm-none">
                <li class="menu-item">
                    <router-link to="/projects" class="item-link mobile-item"><span class="material-icons">inventory_2</span></router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: "NavigationBar"
}
</script>

<style lang="scss">
    .navigation-bar {
        height: 60px;

        position: sticky;

        top: 0;

        width: 100%;

        background-color: var(--front);

        box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.5);

        z-index: 950;

        img.navigation-bar-logo {
            height: 42px;
        }

        ul.navigation-bar-menu {
            display: flex;

            list-style-type: none;

            li.menu-item a.item-link {
                display: flex;
                align-items: center;
                
                border-radius: 12px;

                padding: 12px;

                color: var(--text);
                transition: background-color .2s ease-in;

                font-size: 14px;
                font-weight: 800;
                line-height: 1;

                &.mobile-item {
                    border: 1px dashed var(--accent);
                }

                /* There is not an element nested that has the active class, so it is & */
                &.active {
                    background-color: var(--muted);
                    
                }
            }
        }

    }


</style>