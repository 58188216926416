<template>
    <div class="profile-wrapper">
        <Section>
            <Article heading="Alexander" subheading="Software Engineer">
                <template v-slot:prepend>
                    <RoundImage src="/assets/avatar.png" />
                </template>
                <template v-slot:text>
                    <LinkColumn :dataArray="links" header="Links"/>
                </template>
            </Article>
        </Section>
    </div>
</template>
<script>
import Section from '@/components/Section';
import RoundImage from '@/components/RoundImage';
import Article from '@/components/Article';
import LinkColumn from '@/components/LinkColumn';


import Links from '@/data/links';

export default {
    name: 'Profile',
    components: {
        Section,
        Article,
        RoundImage,
        LinkColumn
    },
    computed: {
        links() {
            return Links.items;
        } 
    }
}
</script>

<style lang="scss">
    .profile-wrapper {
        margin-top: 24px;
        margin-bottom: 24px;

        @media (min-width: 768px) {
            top: calc(24px + 60px);
            position: sticky;
        }
    }
</style>