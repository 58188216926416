<template>
    <div class="mini-section">
        <p class="mini-section-header"> {{ header }} 
            <slot v-if="hasFlaire" name="flaire" />
        </p>
        <div v-if="hasText" class="mini-section-text">
            <slot v-if="hasText" name="text" />
        </div>
        <div class="mini-section-container">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: "MiniSection",
    props: [
        "header"
    ],
    computed: {
        hasFlaire() {
            return !! this.$slots.flaire;
        },
        hasText() {
            return !! this.$slots.text;
        }
    }
}
</script>

<style lang="scss">
    .mini-section {
        display: flex;
        flex-flow: column nowrap;

        .mini-section-header {
            font-size: 18px;
            font-weight: 500;
        }

        .mini-section-text {
            font-size: 14px;
            font-weight: 300;

            white-space: wrap;
            overflow-wrap: break-word;

            padding-bottom: 6px;
        }

        .mini-section-container {
            display: flex;
            flex-flow: row wrap;
            
            padding-bottom: 12px;
        }
    }
    
</style>