<template>
    <div id="app">
        <NavigationBar />

        <router-view />

        <Footer />
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar';
import Footer from '@/components/Footer';

export default {
    name: "App",

    components: {
        NavigationBar,
        Footer
    }
}
</script>

<style lang="scss">
    :root {
        --accent: rgb(167, 224, 255);
        --front: rgb(255, 255, 255);
        --back: rgb(255, 255, 255);
        --panel: rgb(255, 255, 255);
        --text: #424356;
        --badge: rgb(176, 194, 218);
        --link: rgb(23, 126, 185);
        --muted: rgba(220, 240, 240);
    }

    *, *::after, *::before {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: var(--link);
    }

    p {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    body {
        position: relative;

        background-color: var(--back);

        /*font-family: 'Chakra Petch', sans-serif;*/
        font-family: 'Rubik', sans-serif;

        margin: 0px;
        
        /* Toggling full screen */
        &.disabled {
            height: 100%;

            overflow: hidden;
        }
    }

    .material-icons {
        font-size: 18px;
    }

</style>
