<template>
    <div class="article">
        <slot v-if="hasPrepend" name="prepend" />

        <div class="article-header">
            <p class="article-heading">
                {{ heading }}
            </p>
            <p class="article-sub-heading">
                {{ subheading }}
            </p>
        </div>
        <div class="article-text">
            <slot v-if="hasText" name="text" />
        </div>
        <div class="content">
            <slot />
        </div>

    </div>
</template>

<script>
export default {
    name: "Article",
    props: [
        "heading",
        "subheading"
    ],
    computed: {
        classObject( ) {
            return {
            };
        },

        hasPrepend( ) {
            return !! this.$slots.prepend;
        },

        hasText( ) {
            return !! this.$slots.text;
        },
    }
}
</script>

<style lang="scss">
.article {
    .article-header {
        .article-heading {
            font-size: 24px;
            font-weight: 700;

            margin-bottom: 0px;
        }
        .article-sub-heading {
            font-size: 14px;
            font-weight: 400;
        }
    }
    
    .article-text {
        font-size: 16px;
        font-weight: 300;

        white-space: wrap;
        overflow-wrap: break-word;
    }

    .content {
        margin-top: 12px;
    }
}
    
</style>
